/*!
 * media-center.js
 * Created by Santiago Prada
 * Copyright © 2016 IBM. All rights reserved.
 */

$(document).ready(function() {

  //Delay action by a few miliseconds to guarrantee the element in present in the dom
  setTimeout(function(){

  // Add style to active links, desktop and mobile
  $("#about-us-link").addClass("primary-page-active");
  $("#about-us-mobile-link").addClass("mobile-primary-page-active");
  $("#media-center-mobile-link").addClass("mobile-secondary-page-active");

},100);

  //Hero button animations
  $("#hero-button-more").on('mouseenter', function(){
    $("#media-hero-bg").addClass("hero-active");
  });
  $("#hero-button-more").on('mouseleave', function(){
    $("#media-hero-bg").removeClass("hero-active");
  });
});
